import { EventPrice } from '@/types/contentful';

export type Currency = 'USD' | 'EUR' | 'GBP' | 'AUD';

export const DEFAULT_CURRENCY: Currency = 'EUR';
export const DEFAULT_CURRENCY_SIGN = '€';
export const DEFAULT_COUNTRY_CODE = 'NL';

export const COUNTRY_CURRENCIES: Record<string, Currency> = {
  AU: 'AUD', // Australia
  GB: 'GBP', // United Kingdom
  NL: 'EUR', // Netherlands
  US: 'USD', // United States
};

export const getCountryCurrency = (countryCode: string): Currency =>
  COUNTRY_CURRENCIES[countryCode] || DEFAULT_CURRENCY;

// to show sign of currencyCode that come from Shopify calls
export const CURRENCY_SIGNS = {
  USD: '$',
  EUR: '€',
  AUD: 'A$',
  GBP: '£',
};

export const getCurrencySign = (currencyCode?: Currency) =>
  currencyCode ? CURRENCY_SIGNS[currencyCode] : DEFAULT_CURRENCY_SIGN;

export const combineCurrencyAndAmount = (
  // to combine prices with their currency sign
  price?: number | string,
  currencyCode?: Currency,
) => {
  const currencySign = getCurrencySign(currencyCode);
  return price && +price > 0 ? `${currencySign}${(+price).toFixed(2)}` : '-';
};

export const formatDisplayPrice = (countryCode: string, price: EventPrice) => {
  // to format prices come from Contentful with Price obj and based off of users' countryCode
  const customerCurrency = getCountryCurrency(countryCode);
  const finalFormattedPrice = combineCurrencyAndAmount(
    //@ts-ignore
    price[customerCurrency.toLowerCase()],
    customerCurrency,
  );

  return finalFormattedPrice;
};
