/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */
import { Buffer } from 'buffer';

const isBase64 = require('is-base64');

export function parseIdFromShopify(id: string) {
  let shopifyId = id;

  if (isBase64(id)) {
    shopifyId = Buffer.from(id, 'base64').toString();
  }

  const shopifyIdDecodedSplit = shopifyId.split('/');
  const finalisedShopifyId = shopifyIdDecodedSplit.at(-1);

  if (finalisedShopifyId === undefined)
    throw new Error('Cannot extract customer ID from Shopify');

  return finalisedShopifyId;
}

export function parseCustomerShopifyStringFromID(id: string) {
  return `gid://shopify/Customer/${id}`;
}

export function parseProductShopifyStringFromID(id: string) {
  return `gid://shopify/Product/${id}`;
}

/**
 * @deprecated this method is deprecated in favor of parseProductVariantShopifyStringFromID which is more descriptive
 */
export function parseProductVariantStringFromID(id: string) {
  return `gid://shopify/ProductVariant/${id}`;
}

export function parseProductVariantShopifyStringFromID(id: string) {
  return `gid://shopify/ProductVariant/${id}`;
}

export function parsePriceListShopifyStringFromID(id: string) {
  return `gid://shopify/PriceList/${id}`;
}

export function parseLineItemShopifyStringFromID(id: string) {
  return `gid://shopify/LineItem/${id}`;
}

export function parsePublicationShopifyStringFromID(id: string) {
  return `gid://shopify/Publication/${id}`;
}
