export const removeArrayDuplicates = (array: Array<any>): Array<any> =>
  Array.from(new Set(array));

export const ensure = <T>(
  argument: T | undefined | null,
  message: string = 'This value was promised to be there.',
): T => {
  if (argument === undefined || argument === null) {
    throw new TypeError(message);
  }

  return argument;
};

export const createArrayTillN = (N: number) =>
  Array.from({ length: N }, (_, i) => (i + 1).toString());
