/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/no-unsafe-return */
'use server';

import { generateHeadersServerSide } from './generateHeadersServerSide';

export type ShopifyCustomHeaders =
  | { 'X-Shopify-Storefront-Access-Token': string }
  | { 'X-Shopify-Access-Token': string };
export type CustomHeaders = ShopifyCustomHeaders & Record<string, string>;

type QueryVariables = Record<string, any>;

interface FetchShopifyArguments {
  query: string;
  queryVariables?: QueryVariables;
  isAdminAPI?: boolean;
  customHeaders?: CustomHeaders;
}

export async function callShopify({
  customHeaders,
  query,
  queryVariables,
  isAdminAPI,
}: FetchShopifyArguments) {
  const { endpoint, accessTokenHeaders } =
    generateHeadersServerSide(isAdminAPI);

  const response = await fetch(endpoint, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...accessTokenHeaders,
      ...customHeaders,
    },
    body: JSON.stringify({
      query,
      variables: queryVariables,
    }),
    cache: 'no-store',
  });

  if (!response.ok) {
    throw new Error(response.statusText ?? `${response.status}`);
  }
  return await response.json();
}
