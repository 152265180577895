import { ValidationErrorCode } from './enums';

// TODO Get rid of the ValidationErrorCode on this file since it's getting replaced
// by the react-hook-form library and just store translations from form validation errors
export const VALIDATION_ERROR_MESSAGES = {
  [ValidationErrorCode.invalidEmail]:
    'Please enter your email address in the format yourname@example.com',
  [ValidationErrorCode.emptyPassword]: 'Please fill in your password',
  [ValidationErrorCode.required]: 'Please enter your ${field}',
  [`${ValidationErrorCode.required}-an-word`]: 'Please enter your ${field}',
  [`${ValidationErrorCode.required}-personal-approach`]:
    'Please fill in your ${field}',
  [ValidationErrorCode.notLongEnough]:
    'Please choose a ${field} with a minimum of ${amount} characters',
  [ValidationErrorCode.matchesOldPassword]:
    "Your new password can't be the same as your old one",
  [ValidationErrorCode.invalid]: 'Please fill in a valid ${field}',
  [ValidationErrorCode.fileIsTooBig]:
    'The file ${name} exceeds the total maximum size of ${size}, please upload a smaller file.',
  [ValidationErrorCode.tooLong]:
    'Please fill in a ${field} less than 256 characters',
};
