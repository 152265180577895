const MAX_SHOWN_ADD_ONS_COUNT = 3;
const MAX_ADD_ONS_ITEM_QUANTITY = 5;

const CART_ATTRIBUTE = {
  CART_CREATED_COUNTRY: 'countryCode',
};

const CART_INDEX_OF_ATTRIBUTE = {
  [CART_ATTRIBUTE.CART_CREATED_COUNTRY]: 0,
};
// 2 extra custom attribute passed to cartLines, because shopify doesn't have all attributes we need in our scenarios
const CART_LINE_ATTRIBUTE = {
  CART_ITEM_TYPE: 'cartItemType', // to be able to distinguish cart item whether it is an event or addon (merchandise), so we can render right component and etc.
  LINE_CREATION_DATE: 'lineCreationDate', // to be able to sort cart line items based on their creation date, shopify doesn't provide that
};

const CART_LINE_INDEX_OF_ATTRIBUTE = {
  [CART_LINE_ATTRIBUTE.CART_ITEM_TYPE]: 0,
  [CART_LINE_ATTRIBUTE.LINE_CREATION_DATE]: 1,
};

const CART_ITEM_TYPE = {
  ADDON: 'addon',
  EVENT: 'event',
};

export {
  MAX_SHOWN_ADD_ONS_COUNT,
  MAX_ADD_ONS_ITEM_QUANTITY,
  CART_ATTRIBUTE,
  CART_INDEX_OF_ATTRIBUTE,
  CART_LINE_ATTRIBUTE,
  CART_LINE_INDEX_OF_ATTRIBUTE,
  CART_ITEM_TYPE,
};
