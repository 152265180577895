import type { Store } from 'redux';

import { authActions, AuthToken } from '@/reducers/authSlice';
import { eventsActions } from '@/reducers/eventsSlice';
import {
  getItemFromLocalStorage,
  removeFromLocalStorage,
  setItemInLocalStorage,
} from './localStorage';
import {
  getItemFromSessionStorage,
  removeFromSessionStorage,
  setItemInSessionStorage,
} from './sessionStorage';

const AUTH_TOKEN_KEY = 'authToken';
export const REMEMBER_USER_KEY = 'rememberUser';

export const storeTokenLocalStorage = (token: AuthToken) =>
  setItemInLocalStorage(AUTH_TOKEN_KEY, token);

export const storeTokenSessionStorage = (token: AuthToken) =>
  setItemInSessionStorage(AUTH_TOKEN_KEY, token);

export const getTokenFromLocalStorage = (): AuthToken | undefined =>
  getItemFromLocalStorage(AUTH_TOKEN_KEY);

export const getTokenFromSessionStorage = (): AuthToken | undefined =>
  getItemFromSessionStorage(AUTH_TOKEN_KEY);

export const clearUserStoredData = () => {
  removeFromLocalStorage(AUTH_TOKEN_KEY);
  removeFromLocalStorage(REMEMBER_USER_KEY);
  removeFromSessionStorage(AUTH_TOKEN_KEY);
};

export const handleLogout = (dispatch: Store['dispatch']) => {
  dispatch(authActions.logout());
  // clean up user tickets
  dispatch(eventsActions.clear());
  clearUserStoredData();
};

export const handleValidateToken = (
  token: AuthToken | undefined,
  dispatch: Store['dispatch'],
) => {
  if (token) {
    const expirationDateSeconds = new Date(token.expiresAt).getTime() / 1000;
    const currentDateSeconds = new Date().getTime() / 1000;
    const twentyFourHoursSeconds = 60 * 60 * 24; // 60 seconds/min * 60 mins/hour * 24 hours
    const isTokenExpired =
      currentDateSeconds + twentyFourHoursSeconds >= expirationDateSeconds;
    if (!isTokenExpired) {
      // Non expired token found in local storage, logging in
      dispatch(authActions.loginWithToken(token));
    } else {
      // Expired token found in local storage, logging out to be sure
      handleLogout(dispatch);
    }
  }

  return token;
};
