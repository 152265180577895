import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction, SerializedError } from '@reduxjs/toolkit';

import { FetchStatus } from '@/types/api';
import {
  AvailablePlatformsToMessage,
  PlatformMessage,
} from '@/types/contentful';
import { getEnvironmentVariableServerSide } from '../../../next/src/utils/server-functions/getEnvServerSide';
import { genericAPIMessages } from '../messages/genericAPI';

export interface PlatformMessageState {
  status: FetchStatus;
  errorMsg?: SerializedError;
  description?: string;
  updatedAt?: string;
  lastReadUpdatedAt?: string;
}

interface PlatformMessageResponse {
  data?: {
    notificationMessage: PlatformMessage;
  };
  error?: string;
}

export const fetchPlatformMessage = createAsyncThunk(
  'platformMessage/fetchPlatformMessage',
  async (
    { platform }: { platform: AvailablePlatformsToMessage },
    { rejectWithValue },
  ) => {
    const { ONAIR_ENDPOINT } = await getEnvironmentVariableServerSide([
      'ONAIR_ENDPOINT',
    ]);
    const response = await fetch(
      `${ONAIR_ENDPOINT}/notifications/alert?platform=${platform as string}`,
      {
        method: 'GET',
        headers: { 'content-type': 'application/json' },
      },
    );

    const { error, data } = (await response.json()) as PlatformMessageResponse;

    if (!response.ok || error) {
      const errorMessage = error ?? genericAPIMessages.error_performing_request;
      return rejectWithValue({
        error: {
          status: 'UNEXPECTED',
          message: errorMessage,
        },
      });
    }
    const { notificationMessage } = data!;
    const { description, updatedAt } = notificationMessage;

    return { description, updatedAt };
  },
);

export const platformMessageSliceInitialState: PlatformMessageState = {
  status: 'NOT_FETCHED',
  description: undefined,
  updatedAt: undefined,
  lastReadUpdatedAt: undefined,
  errorMsg: undefined,
};

export const platformMessageSlice = createSlice({
  name: 'platformMessage',
  initialState: platformMessageSliceInitialState,
  reducers: {
    clearState: (state) => {
      state.description = undefined;
      state.updatedAt = undefined;
    },
    updateLastUpdatedAt: (state, action: PayloadAction<string>) => {
      state.lastReadUpdatedAt = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPlatformMessage.fulfilled, (state, action) => {
      const { description, updatedAt } = action.payload;

      state.status = 'FETCHED';
      state.description = description;
      state.updatedAt = updatedAt;
      state.errorMsg = undefined;
    });
    builder.addCase(fetchPlatformMessage.rejected, (state, action) => {
      state.status = 'FAILED';
      state.description = undefined;
      state.updatedAt = undefined;
      state.errorMsg = action.error;
    });
  },
});

export const { clearState, updateLastUpdatedAt } = platformMessageSlice.actions;
