import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  ShopifyCustomerAccessToken,
  ShopifyCustomerResponse,
  ShopifyCustomerUpdatePayload,
} from '@/types/Shopify';
import fetchShopify from '@/utils/fetchShopify';
import { getCustomerErrorMessage } from '../errors/utils';
import { profileMessages } from '../messages/profile';
import { customerUpdateMutation } from '../queries/profile';

export enum ProfileActionTypes {
  changeProfile = 'profile/changeProfile',
  changeEmail = 'profile/changeEmail',
  changePassword = 'profile/changePassword',
}

export const customerUpdateImplementation = async (
  payload: {
    customerAccessToken: ShopifyCustomerAccessToken['accessToken'];
    customer: ShopifyCustomerUpdatePayload;
  },
  rejectWithValue: any,
) => {
  const { customerAccessToken, customer } = payload;
  const field = Object.keys(customer)[0] ?? undefined;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { errors, data }: ShopifyCustomerResponse = await fetchShopify({
    query: customerUpdateMutation,
    queryVariables: {
      customer,
      customerAccessToken,
    },
  });

  if (
    errors?.length ||
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    data?.customerUpdate?.customerUserErrors?.length > 0
  ) {
    const errorMessage = getCustomerErrorMessage({
      defaultErrorMessage: profileMessages.error_updating_profile,
      errors,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      errorCode: data?.customerUpdate?.customerUserErrors?.[0].code,
      field,
    });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
    return rejectWithValue(errorMessage);
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    ...data.customerUpdate.customer,
    email: customer.email,
  };
};

export const changeProfileAction = createAsyncThunk(
  ProfileActionTypes.changeProfile,
  async (
    payload: {
      customerAccessToken: ShopifyCustomerAccessToken['accessToken'];
      customer: ShopifyCustomerUpdatePayload;
    },
    { rejectWithValue },
  ) => {
    return customerUpdateImplementation(payload, rejectWithValue);
  },
);

export const changeEmailAction = createAsyncThunk(
  ProfileActionTypes.changeEmail,
  async (
    payload: {
      customerAccessToken: ShopifyCustomerAccessToken['accessToken'];
      customer: ShopifyCustomerUpdatePayload;
    },
    { rejectWithValue },
  ) => {
    // TODO: Remove this any
    return customerUpdateImplementation(payload, rejectWithValue);
  },
);

export const changePasswordAction = createAsyncThunk(
  ProfileActionTypes.changePassword,
  async (
    payload: {
      customerAccessToken: ShopifyCustomerAccessToken['accessToken'];
      customer: ShopifyCustomerUpdatePayload;
    },
    { rejectWithValue },
  ) => {
    // TODO: Remove this any
    return customerUpdateImplementation(payload, rejectWithValue);
  },
);
