import type { SerializedError } from '@reduxjs/toolkit';

import type { ValidationErrorCode } from './enums';

export class ValidationError extends Error implements SerializedError {
  readonly code: ValidationErrorCode;

  constructor(code: ValidationErrorCode, message: string) {
    super(message);
    this.code = code;
    this.name = 'ValidationError';
  }
}
