/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ShopifyCustomerResponse } from '@/types/Shopify';
import { callShopify } from '../../../next/src/utils/server-functions/shopifyServerSide';

export type ShopifyCustomHeaders =
  | { 'X-Shopify-Storefront-Access-Token': string }
  | { 'X-Shopify-Access-Token': string };
export type CustomHeaders = ShopifyCustomHeaders & Record<string, string>;

type QueryVariables = Record<string, any>;

interface FetchShopifyArguments {
  query: string;
  queryVariables?: QueryVariables;
  isAdminAPI?: boolean;
  customHeaders?: CustomHeaders;
}

async function fetchShopify({
  customHeaders,
  query,
  queryVariables,
  isAdminAPI,
}: FetchShopifyArguments) {
  const response: ShopifyCustomerResponse = await callShopify({
    customHeaders,
    query,
    queryVariables,
    isAdminAPI,
  });

  return response;
}

export { fetchShopify }; // for test purposes
export default fetchShopify;
