// The window.fetch, node fetch and react native fetch are almost identical.
// So we will use the types from node-fetch.
import type { RequestInfo, RequestInit, Response } from 'node-fetch';

type Fetch = (input: RequestInfo, init?: RequestInit) => Promise<Response>;

// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
let fetchImplementation = (global as any).fetch as unknown as Fetch;

export function setFetchImplementation(fetchImpl: Fetch) {
  fetchImplementation = fetchImpl;
}

export default function fetch(
  input: RequestInfo,
  init?: RequestInit,
): Promise<Response> {
  if (!fetchImplementation)
    return Promise.reject(
      new Error(
        'Fetch not available in environment. Use setFetchImplementation to provide a environment compatible fetch implementation.',
      ),
    );
  return fetchImplementation(input, init);
}
