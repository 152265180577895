import type { NotificationType } from '../enums/Notification';

export enum LinkType {
  'LINK_PRIMARY' = 'link-primary',
  'LINK_SECONDARY' = 'link-secondary',
  'LINK_TERTIARY' = 'link-tertiary',
  'LINK_MAIN' = 'link-main',
  'LINK_STYLED_IN_COMPONENT' = 'link-styled-in-component',
}

export interface LinkProperties {
  linkText: string;
  linkTarget: string;
  preText?: string;
  linkType: LinkType;
  isExternal?: boolean;
  postText?: string;
}

export interface NotificationObject {
  text: LinkProperties | string;
  type: NotificationType;
}
