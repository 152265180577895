interface PushToElevarDataLayerParams {
  eventName?: string;
  auth: any;
  sku?: string;
  productTitle?: string;
  variantTitle?: string;
  price?: string;
  compareAtPrice?: string;
  productId?: string;
  shopifyVariantId?: string;
  poster?: any;
  ecommerce?: any;
  cartTotal?: string;
  lead_type?: 'email' | 'phone';
  email?: string;
}
export function pushToElevarDataLayer({
  eventName,
  auth,
  ecommerce,
  cartTotal,
  lead_type,
  email,
}: PushToElevarDataLayerParams) {
  const userProps = {
    visitor_type: auth || eventName === 'dl_sign_up' ? 'logged_in' : 'guest',
    customer_id: auth?.customerInfo?.id,
    customer_first_name: auth?.customerInfo?.firstName,
    customer_last_name: auth?.customerInfo?.lastName,
    customer_email:
      eventName === 'dl_subscribe' ? email : auth?.customerInfo?.email,
  };
  const delay = eventName! !== 'dl_user_data' ? 500 : 0;
  // The user_date event must triggered on every render and we need to ensure that it executes before any other 'Elevar' events.
  setTimeout(() => {
    window.ElevarDataLayer?.push({
      event: eventName,
      user_properties: userProps,
      lead_type,
      cart_total: cartTotal,
      ecommerce: ecommerce,
    });
  }, delay);
}
