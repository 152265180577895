import type { MultiLoginToken } from '../reducers/authSlice';

export const multiLoginToken = (payload: MultiLoginToken) => {
  return {
    ...payload,
    expiresAt: payload.expiresAt,
    customerAccessToken: payload.customerAccessToken,
    customerInfo: payload.customer,
  };
};
